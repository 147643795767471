import React from 'react'
import { Field } from 'formik'

const PositionInput = () => {
  return (
    <>
      <div className="label">所属・役職</div>
      <p>
        <Field
          as={'input'}
          type={'text'}
          name={`position`}
          className={`common`}
          placeholder={`例）営業課`}
        />
      </p>
    </>
  )
}

export default PositionInput
