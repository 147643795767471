import React from 'react'
import { Field } from 'formik'

const DescriptionTextArea = ({ errors, touched }) => {
  const hasError = errors.description && touched.description

  return (
    <>
      <div className="label">
        お問い合わせ内容
        <span className="require">必須</span>
        {hasError && <span className="error-msg">{errors.description}</span>}
      </div>
      <p>
        <Field
          as={'textarea'}
          type={'text'}
          name={`description`}
          className={`common area ${hasError && 'error'}`}
          placeholder={`できるだけ詳しい内容をお書き下さい。`}
        />
      </p>
    </>
  )
}

export default DescriptionTextArea
