import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import { navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Layout from '../components/Layout'
import CompanyNameInput from '../components/form/CompanyNameInput'
import NameInput from '../components/form/NameInput'
import PositionInput from '../components/form/PositionInput'
import TelInput from '../components/form/TelInput'
import EmailInput from '../components/form/EmailInput'
import DescriptionTextArea from '../components/form/DescriptionTextArea'

const ContactSchema = Yup.object().shape({
  companyName: Yup.string().required('※必須項目です'),
  name: Yup.string().required('※必須項目です'),
  tel: Yup.string().required('※必須項目です'),
  email: Yup.string()
    .email('※メールアドレスのフォーマットが無効です。')
    .required('※必須項目です'),
  description: Yup.string().required('※必須項目です'),
})

const Contact = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // チェックボックスの状態を管理するためのuseStateフック
  const [isAgreed, setIsAgreed] = useState(false)

  return (
    <>
      <Helmet>
        <html className="contact" lang="ja" />
        <title>{`お問い合わせ | ${title}`}</title>
        <meta
          name="description"
          content="お問い合わせフォームよりお気軽にお問い合わせください。"
        />
      </Helmet>
      <Layout>
        <div className="common-header clearfix">
          <h1>お問い合わせ</h1>
          <div className="breadcrumb">
            <li>
              <Link to={`/`}>トップページ</Link>
            </li>
            /<li>お問い合わせ</li>
          </div>
        </div>
        <div className="main-wrapper">
          <div className="row">
            <div className="container">
              <p>
                お客様から多く寄せられるお問い合わせの内容については、
                <Link to={`/faq`}>よくあるご質問</Link>
                に掲載しておりますので、お問い合わせの前にぜひ一度ご確認ください。
              </p>
            </div>
          </div>
          <div className="row">
            <div className="h-wrapper">
              <h2>お問合せフォーム</h2>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <Formik
                initialValues={{
                  companyName: '',
                  name: '',
                  position: '',
                  tel: '',
                  email: '',
                  description: '',
                }}
                onSubmit={(values, actions) => {
                  if (!isAgreed) {
                    alert('個人情報の取扱いに同意してください。')
                    return
                  }

                  fetch('/', {
                    method: 'POST',
                    body: new URLSearchParams({
                      'form-name': 'contact',
                      ...values,
                    }),
                  })
                    .then(() => {
                      actions.resetForm()
                      navigate('/contact-complete')
                    })
                    .catch((err) => {
                      console.error(err)
                    })
                    .finally(() => actions.setSubmitting(false))
                }}
                validationSchema={ContactSchema}
              >
                {({ errors, touched }) => (
                  <Form name="contact" data-netlify={true}>
                    <CompanyNameInput errors={errors} touched={touched} />
                    <NameInput errors={errors} touched={touched} />
                    <PositionInput />
                    <TelInput errors={errors} touched={touched} />
                    <EmailInput errors={errors} touched={touched} />
                    <DescriptionTextArea errors={errors} touched={touched} />

                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          checked={isAgreed}
                          onChange={(e) => setIsAgreed(e.target.checked)}
                        />
                        <span>
                          <a
                            href="/policy#inquiry"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="link-style" /* 新しく追加したクラスを指定 */
                          >
                            個人情報の取扱
                          </a>
                          に同意します
                        </span>
                      </label>
                    </div>

                    <input
                      type="submit"
                      className="link-box"
                      value="お問い合わせ内容を送信する"
                      disabled={!isAgreed}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Contact
