import React from 'react'
import { Field } from 'formik'

const EmailInput = ({ errors, touched }) => {
  const hasError = errors.email && touched.email

  return (
    <>
      <div className="label">
        メールアドレス
        <span className="require">必須</span>
        {hasError && <span className="error-msg">{errors.email}</span>}
      </div>
      <p>
        <Field
          as={'input'}
          type={'email'}
          name={`email`}
          className={`common ${hasError && 'error'}`}
          placeholder={`例) example@i-tech-corp.co.jp`}
        />
      </p>
    </>
  )
}

export default EmailInput
