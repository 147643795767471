import React from 'react'
import { Field } from 'formik'

const CompanyNameInput = ({ errors, touched }) => {
  const hasError = errors.companyName && touched.companyName

  return (
    <>
      <div className="label">
        会社名
        <span className="require">必須</span>
        {hasError && <span className="error-msg">{errors.companyName}</span>}
      </div>
      <p>
        <Field
          as={'input'}
          type={'text'}
          name={`companyName`}
          className={`common ${hasError && 'error'}`}
          placeholder={`例) 株式会社アイテック`}
        />
      </p>
    </>
  )
}

export default CompanyNameInput
