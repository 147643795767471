import React from 'react'
import { Field } from 'formik'

const TelInput = ({ errors, touched }) => {
  const hasError = errors.tel && touched.tel

  return (
    <>
      <div className="label">
        電話番号
        <span className="require">必須</span>
        {hasError && <span className="error-msg">{errors.tel}</span>}
      </div>
      <p>
        <Field
          as={'input'}
          type={'text'}
          name={`tel`}
          className={`common short ${hasError && 'error'}`}
          placeholder={`例)03-1234-5678`}
        />
      </p>
    </>
  )
}

export default TelInput
