import React from 'react'
import { Field } from 'formik'

const NameInput = ({ errors, touched }) => {
  const hasError = errors.name && touched.name

  return (
    <>
      <div className="label">
        お名前
        <span className="require">必須</span>
        {hasError && <span className="error-msg">{errors.name}</span>}
      </div>
      <p>
        <Field
          as={'input'}
          type={'text'}
          name={`name`}
          className={`common ${hasError && 'error'}`}
          placeholder={`例) 山田 太郎`}
        />
      </p>
    </>
  )
}

export default NameInput
